import React from 'react';
import './styles/App.css';
import { Login } from './login/Login';
import { Route, useParams } from 'react-router-dom';
import { HeaderNavBar } from './navBar/HeaderNavBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalStore } from './utilities/GlobalState';
// import { LandingPage } from './landingPage/LandingPage';
import CustomRouter from './utilities/Routing/CustomRouter';
import customHistory from './utilities/Routing/History';
import { PasswordReset } from './login/PasswordReset';
import { Organizations } from './organizations/Organizations';
import { Instructors } from './instructors/Instructors';
import { ManagementPage } from './managementPage/ManagementPage';
import { Modules } from './courses/Courses';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { Classes } from './classes/Classes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LandingPage } from './landingPage/LandingPage';
import { About } from './about/About';

export const App: React.FC = () => {
    const { params } = useParams();
    LicenseInfo.setLicenseKey(
        '0dd79c876624f25ca73c6df778c476ccTz04NTI2OSxFPTE3NDA3NTIyNDgwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
    );
    const [authed, setAuthed] = React.useState(false);
    const globalState = useGlobalStore((state) => state);
    React.useEffect(() => {
        if (globalState?.authenticated && !authed) {
            setAuthed(true);
        } else {
            setAuthed(false);
        }
    }, [globalState?.authenticated]);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {authed && <HeaderNavBar />}
            <CustomRouter history={customHistory}>
                <Route
                    path={params?.length ? '/' + params : '/'}
                    element={
                        globalState.authenticated ? <LandingPage /> : <Login />
                    }
                />
                {globalState?.user?.type !== 'Student' &&
                globalState?.user?.type !== 'Instructor' ? (
                    <Route
                        path={
                            params?.length
                                ? '/organizations' + params
                                : '/organizations'
                        }
                        element={
                            globalState.authenticated ? (
                                <Organizations />
                            ) : (
                                <Login />
                            )
                        }
                    />
                ) : (
                    <></>
                )}
                {globalState?.user?.type !== 'Student' &&
                globalState?.user?.type !== 'Instructor' ? (
                    <Route
                        path="/instructors"
                        element={
                            globalState.authenticated ? (
                                <Instructors />
                            ) : (
                                <Login />
                            )
                        }
                    />
                ) : (
                    <></>
                )}
                {globalState?.user?.type !== 'Student' &&
                globalState?.user?.type !== 'Instructor' &&
                globalState?.user?.type !== 'OrganizationAdmin' ? (
                    <Route
                        path="/modules"
                        element={
                            globalState.authenticated ? <Modules /> : <Login />
                        }
                    />
                ) : (
                    <></>
                )}
                <Route
                    path="/classes"
                    element={
                        globalState.authenticated ? <Classes /> : <Login />
                    }
                />
                <Route path="/passwordReset" element={<PasswordReset />} />
                <Route path="/about" element={<About />} />
                <Route path="/login" element={<Login />} />
                {globalState?.user?.type !== 'Student' &&
                globalState?.user?.type !== 'Instructor' ? (
                    <Route
                        path="/management"
                        element={
                            globalState.authenticated ? (
                                <ManagementPage />
                            ) : (
                                <Login />
                            )
                        }
                    />
                ) : (
                    <></>
                )}
                <Route path="*" element={<Login />} />
            </CustomRouter>
        </LocalizationProvider>
    );
};
