import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme, editUserActive } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Card, Grid, IconButton } from '@mui/material';
import { CreateAccountModal } from './CreateAccountModal';
import { AuthGet } from '../utilities/Routing/Requests';
import { ApiUser, OrganizationUser } from '../interfaces/Interfaces';
import { AxiosResponse } from 'axios';
import { useGlobalStore } from '../utilities/GlobalState';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { EditAccountModal } from './EditAccountModal';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Datagrid } from '../utilities/Datagrid';

export const ManagementPage = () => {
    const [createAccountOpen, setCreateAccountOpen] = React.useState(false);
    const [editAccountOpen, setEditAccountOpen] = React.useState(false);
    const [userToEdit, setUserToEdit] = React.useState<OrganizationUser>();
    const [userAccounts, setUserAccounts] = React.useState<Array<ApiUser>>([]);
    // const [customers, setCustomers] = React.useState<Array<ApiCustomer>>([]);

    const globalState = useGlobalStore((state) => state);

    const getUserList = () =>
        AuthGet('/user/list', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        }).then((res: AxiosResponse<Array<ApiUser>>) => {
            setUserAccounts(res?.data);
        });
    // const getCustomers = () =>
    //     AuthGet('/Customers', {
    //         'x-access-token': globalState?.authToken,
    //         canCache: true,
    //     }).then((res: AxiosResponse<Array<ApiCustomer>>) => {
    //         setCustomers(res?.data);
    //     });
    React.useEffect(() => {
        getUserList();
        // getCustomers();
    }, []);
    const handleCreateAccountOpen = () => setCreateAccountOpen(true);
    const handleCreateAccountClose = (refresh?: boolean) => {
        if (refresh) {
            getUserList();
        }
        setCreateAccountOpen(false);
    };
    const handleEditAccountOpen = () => setEditAccountOpen(true);
    const handleEditAccountClose = (refresh?: boolean) => {
        if (refresh) {
            getUserList();
        }
        setEditAccountOpen(false);
    };

    const getRowId = (row: any) => {
        return row.id;
    };

    const toggleUserActive = (email: string, active: boolean) => {
        return editUserActive(email, active, globalState.authToken);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <CreateAccountModal
                open={createAccountOpen}
                handleClose={handleCreateAccountClose}
            />
            {userToEdit && (
                <EditAccountModal
                    open={editAccountOpen}
                    handleClose={handleEditAccountClose}
                    userAccount={userToEdit}
                    // customers={customers}
                />
            )}
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                    maxWidth: '100vp',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card sx={{ padding: '10px', margin: '10px' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid xs={8} sx={{ alignSelf: 'center' }}>
                            <div>User Management</div>
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                title="Create Account"
                                onClick={handleCreateAccountOpen}
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: 'green',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                            >
                                <PersonAddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {Datagrid({
                        getRowId: getRowId,
                        pageSize: 20,
                        columns: [
                            {
                                field: 'userEmail',
                                headerName: 'Email',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'firstName',
                                headerName: 'First Name',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'lastName',
                                headerName: 'Last Name',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'type',
                                headerName: 'Type',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'orgId',
                                headerName: 'Org ID',
                                type: 'string',
                                flex: 0.5,
                            },
                            {
                                field: 'active',
                                headerName: 'Active',
                                type: 'number',
                                flex: 0.25,
                                renderCell: (params) => (
                                    <>
                                        {params?.row?.active ? (
                                            <CheckBoxIcon color="success" />
                                        ) : (
                                            <DisabledByDefaultIcon color="error" />
                                        )}
                                    </>
                                ),
                            },
                            {
                                field: 'Action',
                                headerName: 'Actions',
                                flex: 0.4,
                                sortable: false,
                                renderCell: (params) => (
                                    <>
                                        <IconButton
                                            title="Edit Details"
                                            onClick={() => {
                                                setUserToEdit(params?.row);
                                                handleEditAccountOpen();
                                            }}
                                        >
                                            <SettingsApplicationsIcon color="warning" />
                                        </IconButton>
                                        <IconButton
                                            title={
                                                !params?.row?.active
                                                    ? 'Click to Re-Activate'
                                                    : 'Click to De-Activate'
                                            }
                                            onClick={
                                                !params?.row?.active
                                                    ? () => {
                                                          toggleUserActive(
                                                              params?.row
                                                                  ?.userEmail,
                                                              !params?.row
                                                                  ?.active,
                                                          ).then(() =>
                                                              getUserList(),
                                                          );
                                                      }
                                                    : () => {
                                                          toggleUserActive(
                                                              params?.row
                                                                  ?.userEmail,
                                                              !params?.row
                                                                  ?.active,
                                                          ).then(() =>
                                                              getUserList(),
                                                          );
                                                      }
                                            }
                                        >
                                            {!params?.row?.active ? (
                                                <PersonIcon color="success" />
                                            ) : (
                                                <PersonOffIcon color="error" />
                                            )}
                                        </IconButton>
                                    </>
                                ),
                            },
                        ],
                        data: userAccounts,
                    })}
                </Card>
            </Box>
        </ThemeProvider>
    );
};
