import React from 'react';
import { Box, Button, Card, Modal, Typography } from '@mui/material';
import { Blog } from '../interfaces/Interfaces';
import { AuthDelete } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';

export const DeleteBlogModal = (
    refreshBlogs: () => void,
    isOpen: boolean,
    setIsOpen: (open: boolean) => void,
    authToken: string,
    blog?: Blog,
) => {
    const onClose = () => setIsOpen(false);

    const styles = {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3d5170',
        '&::hover': {
            color: '#2b394f',
        },
    };

    const deleteBlog = () => {
        const id = blog?.ID || 0;
        AuthDelete(`/blog/delete/${id}`, {
            'x-access-token': authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    refreshBlogs();
                    toast.success('Blog Successfully Deleted!');
                    onClose();
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Card sx={{ width: '90%', margin: '10px' }}>
                <h2 style={{ textAlign: 'center' }}>Delete Blog Post</h2>
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography style={styles} variant={'h5'} gutterBottom>
                        {
                            'Are you sure you want to PERMANENTLY delete this blog post? This action CAN NOT be reversed!'
                        }
                    </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={deleteBlog}
                    >
                        Delete
                    </Button>
                </Box>
            </Card>
        </Modal>
    );
};
