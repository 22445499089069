import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { OrganizationUser } from '../interfaces/Interfaces';
// import {
//     customerSelectOptionMapper,
//     customerIdStringToSelectOption,
// } from '../utilities/Utilites';
import { useGlobalStore } from '../utilities/GlobalState';
// import { SearchableSelect } from '../utilities/SearchableSelect';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    userAccount: OrganizationUser;
    // customers: Array<ApiCustomer>;
}

export const EditAccountModal = ({
    open,
    handleClose,
    userAccount,
}: // customers,
Props) => {
    const globalState = useGlobalStore((state) => state);
    // const [customer, setCustomer] = React.useState<string | null>();

    // React.useEffect(() => {
    //     if (userAccount?.customerId) {
    //         setCustomer(userAccount?.customerId);
    //     }
    // }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userBody = {
            ...userAccount,
            userEmail: data.get('userEmail'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            type: data.get('type'),
            // customerId: customer?.replace(/(^.*\(|\).*$)/g, '') || '',
        };

        if (
            userAccount.firstName !== userBody.firstName ||
            userAccount.lastName !== userBody.lastName ||
            userAccount.type !== userBody.type ||
            userAccount.userEmail !== userBody.userEmail
        ) {
            AuthPost('/user/editfromid', userBody, {
                'x-access-token': globalState.authToken,
            })
                .then((userRes) => {
                    if (userRes?.status === 201 || userRes?.status === 204) {
                        toast.success('Account Details Edited Successfully!');
                        handleClose(true);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        } else {
            handleClose(true);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {`Edit Account (${userAccount.userEmail})`}
                    </Typography>
                    <Typography>{`ID: (${userAccount.id})`}</Typography>
                    <Typography>
                        {`Organization ID: (${userAccount.orgId})`}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="given-name"
                            autoFocus
                            defaultValue={userAccount?.firstName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            defaultValue={userAccount?.lastName}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={'userEmail'}
                            label={'Email'}
                            name={'userEmail'}
                            defaultValue={userAccount?.userEmail}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name={'type'}
                            label={'Type'}
                            type={'type'}
                            defaultValue={userAccount?.type}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            EDIT USER
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
