import React from 'react';
import { Box, Button, Card, Modal, TextField } from '@mui/material';
import { formatDateWithTimeAndT } from '../utilities/Utilites';
import { Blog } from '../interfaces/Interfaces';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { DateTime } from 'luxon';
import { AuthPut } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';

export const EditBlogModal = (
    refreshBlogs: () => void,
    isOpen: boolean,
    setIsOpen: (open: boolean) => void,
    author: string,
    authToken: string,
    blog?: Blog,
) => {
    const Textarea = styled(BaseTextareaAutosize)(
        () => `
            width: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            padding: 16.5px 14px;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:hover {
            border-color: black;
            }

            &:focus {
            border-color: #f44336;
            box-shadow: 0 0 0 1px #f44336;
            }

            // firefox
            &:focus-visible {
            outline: 0;
            }
        `,
    );

    let title = blog?.Title || '';
    let content = blog?.Content || '';
    let url = blog?.Img_Url || '';

    const onClose = () => setIsOpen(false);
    const changeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        title = value;
    };
    const changeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        url = value;
    };
    const changeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        content = value;
    };
    const editBody = () => {
        const dateTimeNow = DateTime.now();
        const createdDate = formatDateWithTimeAndT(dateTimeNow);
        const body = {
            Title: title,
            Author: author,
            Img_Url: url,
            Content: content,
            Created_Date: createdDate,
        };
        const id = blog?.ID || 0;
        AuthPut(`/blog/update/${id}`, body, {
            'x-access-token': authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    refreshBlogs();
                    toast.success('Blog Successfully Edited!');
                    onClose();
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Card sx={{ width: '90%', margin: '10px', overflow: 'auto' }}>
                <h2 style={{ textAlign: 'center' }}>Edit Blog Post</h2>
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        autoFocus
                        sx={{ marginBottom: '16px' }}
                        onChange={changeTitle}
                        defaultValue={title}
                    />
                    <TextField
                        required
                        fullWidth
                        id="Img_Url"
                        label="Image Url"
                        name="Img_Url"
                        autoFocus
                        sx={{ marginBottom: '16px' }}
                        onChange={changeUrl}
                        defaultValue={url}
                    />
                    <Textarea
                        style={{
                            fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                            fontWeight: '400',
                            fontSize: '1rem',
                            lineHeight: '1.4375em',
                            letterSpacing: '0.00938em',
                            color: 'rgba(0, 0, 0, 0.87)',
                            boxSizing: 'border-box',
                            position: 'relative',
                            cursor: 'text',
                            display: 'inline-flex',
                            alignItems: 'center',
                            borderRadius: '4px',
                        }}
                        aria-label="content"
                        minRows={15}
                        placeholder="Content *"
                        required
                        id="content"
                        name="content"
                        onChange={changeContent}
                        defaultValue={content}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={editBody}
                    >
                        Edit
                    </Button>
                </Box>
            </Card>
        </Modal>
    );
};
