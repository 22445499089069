import React, { useState } from 'react';
import {
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment,
    SxProps,
    Theme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type Props<T> = {
    label: string;
    allOptions: Array<T>;
    searchKey: string;
    selected: T;
    callBackOnSelect?: (selected: T) => void;
    sx?: SxProps<Theme>;
};

export const SearchableDropdown = <T,>({
    label,
    allOptions,
    searchKey,
    selected,
    callBackOnSelect,
    sx,
}: Props<T>): JSX.Element => {
    const [searchText, setSearchText] = useState('');
    const displayedOptions: Array<T> = [];
    allOptions.forEach((option: T) => {
        if (
            typeof option?.[searchKey as keyof T] === 'string' &&
            (option?.[searchKey as keyof T] as string)
                ?.toLowerCase()
                ?.indexOf(searchText?.toLowerCase()) > -1
        ) {
            displayedOptions.push(option);
        }
    });

    const selectItem = (option: T) => {
        if (callBackOnSelect) {
            callBackOnSelect(option);
        }
    };

    return (
        <Box sx={sx}>
            <FormControl fullWidth>
                <InputLabel id="search-select-label">{label}</InputLabel>
                <Select
                    // Disables auto focus on MenuItems and allows TextField to be in focus
                    MenuProps={{ autoFocus: false }}
                    labelId="search-select-label"
                    id="search-select"
                    value={selected?.[searchKey as keyof T] || ''}
                    label={label}
                    // onChange={(e) => setSelectedOption(e.target.value)}
                    onClose={() => setSearchText('')}
                    // This prevents rendering empty string in Select's value
                    // if search text would exclude currently selected option.
                    // renderValue={() => selectedOption}
                >
                    {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
                    <ListSubheader>
                        <TextField
                            size="small"
                            sx={{ paddingTop: '8px' }}
                            // Autofocus on textfield
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== 'Escape') {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                    e.stopPropagation();
                                }
                            }}
                        />
                    </ListSubheader>
                    {displayedOptions.map((option, i) => (
                        <MenuItem
                            key={i}
                            value={option?.[searchKey as keyof T] as string}
                            onClick={() => selectItem(option)}
                        >
                            {option?.[searchKey as keyof T] as string}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
