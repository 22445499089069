import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, Grid } from '@mui/material';
// import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState, Module } from '../interfaces/Interfaces';
import { useGlobalStore } from '../utilities/GlobalState';
import { AuthPost } from '../utilities/Routing/Requests';
import { GradebookAnswerCard } from './GradebookAnswerCard';
// import { ModulePreviewCard } from '../modules/ModulePreviewCard';

export interface Props {
    open: boolean;
    modules: Array<Module>;
    classID?: number;
    studentID?: number;
    handleToggleGradeBook: (studentID?: number) => void;
    userType: string;
    selectedModule?: number;
}

export const GradeBookModal = ({
    open,
    handleToggleGradeBook,
    modules,
    classID,
    studentID,
    userType,
    selectedModule,
}: Props) => {
    const globalState: any = useGlobalStore((state: GlobalState) => state);
    const [gradebook, setGradebook] = React.useState<Array<any>>([]);
    const [localModules, setLocalModules] = React.useState<Array<any>>([]);
    const [countCorrect, setCountCorrect] = React.useState<Array<number>>([]);
    const [totalGrades, setTotalGrades] = React.useState<Array<number>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    // const [loading, setLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (selectedModule && !localModules?.length) {
            setLocalModules(
                modules.filter((item: any) => item.id === selectedModule),
            );
        } else if (!localModules?.length) {
            setLocalModules(modules);
        }
    }, [selectedModule]);
    React.useEffect(() => {
        setTotalGrades([]);
        setCountCorrect([]);
        setLoading(true);
        const newGrades = [...totalGrades];
        const countPromise = new Promise((resolve) => {
            return resolve(
                localModules.forEach((mod: any, i: number) => {
                    AuthPost(
                        '/assessmentdetail/getAssessmentDetailCountByID',
                        { moduleID: mod.id },
                        {
                            'x-access-token': globalState.authToken,
                            canCache: true,
                        },
                    )
                        .then((data) => {
                            newGrades[i] =
                                Number(Object.values(data.data)[0]) || 0;
                            setTotalGrades(newGrades);
                        })
                        .then(() => {
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.error(err.toString());
                            setLoading(false);
                        });
                }),
            );
        });
        countPromise.then(() => {
            setCountCorrect(newGrades);
            setLoading(false);
        });
    }, [localModules]);
    React.useEffect(() => {
        setLoading(true);
        const buildGradebookObj = async (
            assessmentDetailID: string,
            answer: string,
            moduleID: number,
            correct?: boolean,
        ) => {
            return AuthPost(
                '/assessmentdetail/getAssessmentDetailByID',
                { assessmentDetailID: assessmentDetailID },
                {
                    'x-access-token': globalState.authToken,
                    canCache: true,
                },
            )
                .then((data) => {
                    let resolvedAnswer = answer;
                    if (data.data.type === 'mc') {
                        resolvedAnswer = JSON.parse(
                            data.data.assessmentChoices,
                        )[answer];
                    }
                    return {
                        answer: resolvedAnswer,
                        question: data.data.assessmentDetail,
                        type: data.data.type,
                        correctAnswer: JSON.parse(data.data.correctAnswer),
                        correct: correct,
                        assessmentDetailID: assessmentDetailID,
                        assessmentChoices: JSON.parse(
                            data.data.assessmentChoices,
                        ),
                        moduleID: moduleID,
                    };
                })
                .catch((err) => {
                    console.error(err.toString());
                    setLoading(false);
                });
        };

        const getBody = {
            classID: classID,
            userID: studentID,
        };
        AuthPost('/gradebook/getusergradebook', getBody, {
            'x-access-token': globalState.authToken,
            canCache: false,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    let responseData = res.data;
                    if (selectedModule) {
                        responseData = res.data.filter(
                            (item: any) => item.moduleID === selectedModule,
                        );
                    }
                    const gbData = responseData?.map((gradebookItem: any) => {
                        return buildGradebookObj(
                            gradebookItem.assessmentDetailID,
                            gradebookItem.answer,
                            gradebookItem.moduleID,
                            gradebookItem.correct,
                        );
                    });
                    Promise.all(gbData).then((resProms) => {
                        setGradebook(resProms);
                    });
                    return responseData;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
            });
    }, []);
    React.useEffect(() => {
        setLoading(true);
        const newCount: any = [];
        const countPromise = new Promise((resolve) => {
            return resolve(
                localModules.forEach((mod: any, i: number) => {
                    gradebook
                        .filter((item) => item.moduleID === mod.id)
                        ?.forEach((grade: any) => {
                            if (grade?.correct) {
                                newCount[i] = (newCount[i] || 0) + 1;
                            }
                        });
                }),
            );
        });
        countPromise.then(() => {
            setCountCorrect(newCount);
            setLoading(false);
        });
    }, [gradebook, localModules]);

    const handleSetGraded = () => {
        setLoading(true);
        const body = {
            userID: studentID,
            moduleID: selectedModule,
            classID: classID,
        };

        AuthPost('/gradebook/gradebooksetgraded', body, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    handleToggleGradeBook();
                    setLoading(false);
                    return res.data;
                } else {
                    handleToggleGradeBook();
                    setLoading(false);
                    return res;
                }
            })
            .catch((err) => {
                console.error(err.toString());
                setLoading(false);
                handleToggleGradeBook();
            });
    };
    return (
        <Modal
            open={open}
            onClose={() => handleToggleGradeBook()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                outline: 'none',
            }}
        >
            <Card
                sx={{
                    width: '800px',
                    margin: '10px',
                    outline: 'none',
                    overflow: 'auto',
                }}
            >
                {loading ? (
                    <></>
                ) : (
                    <Box
                        sx={{
                            margin: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container>
                            {localModules.map((mod: any, i: number) => {
                                // finish adding a button to submit module as graded. Check to make sure all questions are set as correct or incorrect before allowing submission.
                                return (
                                    <>
                                        <Grid
                                            sx={{
                                                textAlignLast: 'center',
                                            }}
                                            xs={12}
                                        >
                                            <Card
                                                key={mod.id}
                                                sx={{
                                                    margin: '0 10px 0 10px',
                                                    outline: 'none',
                                                    width: '100%',
                                                }}
                                            >
                                                <Typography
                                                    component="h1"
                                                    variant="h5"
                                                >
                                                    {`Grade Book - Course: ${mod.title}`}
                                                </Typography>
                                                <Box sx={{ mt: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        onClick={
                                                            handleSetGraded
                                                        }
                                                    >
                                                        SUBMIT GRADE
                                                    </Button>
                                                </Box>
                                                <>
                                                    Correct: {countCorrect[i]}/
                                                    {totalGrades[i]}
                                                </>
                                            </Card>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                textAlignLast: 'center',
                                            }}
                                            xs={12}
                                        >
                                            {gradebook?.length > 0 &&
                                                gradebook
                                                    .filter(
                                                        (item) =>
                                                            item.moduleID ===
                                                            mod.id,
                                                    )
                                                    .map(
                                                        (
                                                            gradebookItem: any,
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        gradebookItem?.id
                                                                    }
                                                                >
                                                                    <GradebookAnswerCard
                                                                        question={
                                                                            gradebookItem?.question ||
                                                                            ''
                                                                        }
                                                                        answer={
                                                                            gradebookItem?.answer ||
                                                                            ''
                                                                        }
                                                                        type={
                                                                            gradebookItem?.type ||
                                                                            ''
                                                                        }
                                                                        correct={
                                                                            gradebookItem?.correct
                                                                        }
                                                                        authToken={
                                                                            globalState.authToken
                                                                        }
                                                                        userID={
                                                                            studentID
                                                                        }
                                                                        assessmentDetailID={
                                                                            gradebookItem?.assessmentDetailID
                                                                        }
                                                                        classID={
                                                                            classID
                                                                        }
                                                                        userType={
                                                                            userType
                                                                        }
                                                                        correctAnswersMC={
                                                                            gradebookItem?.correctAnswer ||
                                                                            []
                                                                        }
                                                                        assessmentChoices={
                                                                            gradebookItem?.assessmentChoices ||
                                                                            []
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                        <Box sx={{ mt: 1 }}>
                            <Button
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSetGraded}
                            >
                                SUBMIT GRADE
                            </Button>
                        </Box>
                    </Box>
                )}
            </Card>
        </Modal>
    );
};
