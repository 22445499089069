import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Divider,
    Grid,
    IconButton,
    Typography,
    styled,
} from '@mui/material';
import { defaultTheme, formatDateNoTimeMonth } from '../utilities/Utilites';
import { Blog } from '../interfaces/Interfaces';
import { DateTime } from 'luxon';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
    grid: {
        marginTop: '20px',
        minHeight: '391px',
        padding: '10px',
    },
    card: {
        minWidth: 0,
        marginLeft: '15px',
        marginRight: '15px',
        margin: 'auto',
        transition: '0.3s',
        minHeight: '391px',
        borderRadius: '.625rem!important',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
    },
    content: {
        textAlign: 'left',
        padding: defaultTheme.spacing(3),
    },
    divider: {
        margin: `${defaultTheme.spacing(2)}px 0`,
    },
    heading: {
        fontFamily: 'sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3d5170',
        '&::hover': {
            color: '#2b394f',
        },
    },
    subheading: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        lineHeight: 1.8,
    },
};

const StyledChip = styled(Chip)(() => ({
    chip: {
        color: '#FFFFFF',
        top: '0.9375rem',
        right: '0.9375rem',
        position: 'absolute',
        textTransform: 'uppercase',
    },
}));

export const BlogCard = (
    blog: Blog,
    isAdmin: boolean,
    setSelectedBlog: (blog: Blog) => void,
    setModelIsOpen: (open: boolean) => void,
    modalIsOpen: boolean,
    setEditModelIsOpen: (open: boolean) => void,
    setDeleteModelIsOpen: (open: boolean) => void,
    id: string,
) => {
    const colors = (role: string) => {
        switch (role) {
            case 'PACKAGING':
                return '#dedaf4';
            case 'EDU-WELDING':
                return '#ffd6a5';
            case 'EDU-ROCKWELL':
                return '#e4f1ee';
            case 'EDU-MATERIAL HANDLING':
                return '#d9edf8';
            case 'ADMIN':
                return '#ffadad';
            default:
                return '#ffadad';
        }
    };
    const toText = (node: string) => {
        const tag = document.createElement('div');
        tag.innerHTML = node || '';
        node = tag.innerText;
        return node;
    };

    const shortenText = (
        text: string,
        startingPoint: number,
        maxLength: number,
    ) => {
        return text.length > maxLength
            ? text.slice(startingPoint, maxLength)
            : text;
    };

    const date = blog.Created_Date;
    return (
        <Grid
            xs={12}
            sm={12}
            lg={4}
            style={styles.grid}
            item
            id={'blog-grid-root' + id}
            key={'blog-grid-root' + id}
        >
            <Card
                style={styles.card}
                key={'blog-card' + id}
                id={'blog-card' + id}
            >
                <CardMedia
                    key={'card-media' + id}
                    style={{
                        minHeight: '10rem',
                        position: 'relative',
                        textAlign: 'right',
                    }}
                    image={blog?.Img_Url}
                    onClick={() => {
                        setSelectedBlog(blog);
                        setModelIsOpen(!modalIsOpen);
                    }}
                >
                    {blog?.Role?.split(',').map((role) => {
                        if (role?.length && isAdmin) {
                            return (
                                <StyledChip
                                    key={'syled-chip-' + role + id}
                                    label={role}
                                    size="small"
                                    style={{
                                        backgroundColor: colors(role),
                                    }}
                                />
                            );
                        } else {
                            return <></>;
                        }
                    })}
                </CardMedia>
                <CardContent
                    key={'card-content-' + id}
                    style={{
                        paddingTop: '2.1875rem',
                        minHeight: '120px',
                        paddingBottom: '0',
                        textAlign: 'left',
                        padding: defaultTheme.spacing(3),
                    }}
                    onClick={() => {
                        setSelectedBlog(blog);
                        setModelIsOpen(!modalIsOpen);
                    }}
                >
                    <Typography
                        key={'card-grid-typography1-' + id}
                        style={styles.heading}
                        variant={'h5'}
                        gutterBottom
                        onClick={() => {
                            setSelectedBlog(blog);
                            setModelIsOpen(!modalIsOpen);
                        }}
                    >
                        {shortenText(blog?.Title, 0, 75)}
                    </Typography>
                    <Typography
                        key={'card-grid-typography2-' + id}
                        style={styles.subheading}
                        variant="body1"
                        onClick={() => {
                            setSelectedBlog(blog);
                            setModelIsOpen(!modalIsOpen);
                        }}
                    >
                        {shortenText(toText(blog?.Content), 0, 120) + '...'}
                    </Typography>
                </CardContent>
                <Divider style={styles.divider} />
                <CardActions
                    key={'card-actions-' + id}
                    style={{
                        paddingLeft: '1.5625rem',
                        paddingRight: '1.5625rem',
                    }}
                >
                    <Grid container key={'card-grid-container-' + id}>
                        <Grid
                            key={'card-grid-item1-' + id}
                            item
                            xs={8}
                            style={{
                                alignSelf: 'center',
                            }}
                        >
                            <Typography
                                display="block"
                                variant="body1"
                                style={{
                                    width: '100%',
                                    fontSize: '14px',
                                    color: '#868e96',
                                    display: 'inline-block',
                                    textAlign: 'left',
                                    alignContent: 'center',
                                }}
                                gutterBottom
                            >
                                {formatDateNoTimeMonth(DateTime.fromISO(date))}
                            </Typography>
                        </Grid>
                        <Grid
                            key={'card-grid-item2-' + id}
                            item
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            {isAdmin ? (
                                <>
                                    <IconButton
                                        key={'card-grid-button1-' + id}
                                        title="Edit Details"
                                        onClick={() => {
                                            setSelectedBlog(blog);
                                            setEditModelIsOpen(true);
                                        }}
                                    >
                                        <SettingsApplicationsIcon color="warning" />
                                    </IconButton>
                                    <IconButton
                                        key={'card-grid-button2-' + id}
                                        title="Delete"
                                        onClick={() => {
                                            setSelectedBlog(blog);
                                            setDeleteModelIsOpen(true);
                                        }}
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};
