import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
// import { toast } from 'react-toastify';
import { ApiOrganization } from '../interfaces/Interfaces';
import { AuthPost } from '../utilities/Routing/Requests';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    organization: ApiOrganization;
    refreshOrgList: () => void;
    authToken: string;
}

export const EditOrganizationModal = ({
    open,
    handleClose,
    organization,
    refreshOrgList,
    authToken,
}: Props) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userBody = {
            name: data.get('name'),
            phone: data.get('phone'),
            type: data.get('type'),
            parent: data.get('parent'),
            billType: data.get('billType'),
            reseller: data.get('reseller'),
            addressLine1: data.get('addressLine1'),
            addressLine2: data.get('addressLine2'),
            city: data.get('city'),
            state: data.get('state'),
            zipcode: data.get('zipcode'),
            orgGUID: organization.organization,
            // addressActive: data.get('true'),
            // active: data.get('true'),
        };
        AuthPost('/organization/editorg', userBody, {
            'x-access-token': authToken,
        })
            .then((userRes) => {
                if (
                    userRes?.status === 200 ||
                    userRes?.status === 201 ||
                    userRes?.status === 204
                ) {
                    refreshOrgList();
                    handleClose(true);
                }
            })
            .catch((err) => {
                console.error(err.toString());
            });
        handleClose(true);
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {`Edit Organization (${organization.name})`}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Organization Name"
                            name="name"
                            defaultValue={organization?.name}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="Phone"
                            name="phone"
                            defaultValue={organization?.phone}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="type"
                            label="Type"
                            name="type"
                            defaultValue={organization?.type}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="parent"
                            label="Parent Organization"
                            name="parent"
                            defaultValue={organization?.parent}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="billType"
                            label="Billing Type"
                            name="billType"
                            defaultValue={organization?.billType}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="reseller"
                            label="Reseller"
                            name="reseller"
                            defaultValue={organization?.reseller}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="addressLine1"
                            label="Address Line 1"
                            name="addressLine1"
                            defaultValue={organization?.addressLine1}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="addressLine2"
                            label="Address Line 2"
                            name="addressLine2"
                            defaultValue={organization?.addressLine2}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="city"
                            label="City"
                            name="city"
                            defaultValue={organization?.city}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="state"
                            label="State"
                            name="state"
                            defaultValue={organization?.state}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="zipcode"
                            label="Zip Code"
                            name="zipcode"
                            defaultValue={organization?.zipcode}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            EDIT Organization
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
