import * as React from 'react';
import { Card, Grid, IconButton, Modal, ThemeProvider } from '@mui/material';
import { OrganizationUser } from '../interfaces/Interfaces';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import { Datagrid } from '../utilities/Datagrid';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { defaultTheme, toggleActiveUser } from '../utilities/Utilites';
// import SaveIcon from '@mui/icons-material/Save';
// import CancelIcon from '@mui/icons-material/Close';
// import {
//     GridRowId,
//     GridRowModes,
//     GridRowModesModel,
// } from '@mui/x-data-grid-pro';

interface Props {
    open: boolean;
    toggleModal: (
        students: Array<OrganizationUser> | null,
        professor: OrganizationUser | null,
    ) => void;
    students: Array<OrganizationUser> | undefined;
    toggleEditStudentModal: (student: OrganizationUser | null) => void;
    selectedInstructor: OrganizationUser | null;
    authToken: string;
}
export const StudentManagementModal = ({
    open,
    toggleModal,
    students,
    toggleEditStudentModal,
    selectedInstructor,
    authToken,
}: Props) => {
    // const [rows, setRows] = React.useState(students);
    // const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    //     {},
    // );

    const handleAddStudentModal = () => {
        toggleEditStudentModal(null);
    };

    const handleIsOpen = (selectedStudents: Array<OrganizationUser> | null) => {
        toggleModal(selectedStudents, selectedInstructor);
    };

    // const handleEditClick = (id: GridRowId) => () => {
    //     setRowModesModel({
    //         ...rowModesModel,
    //         [id]: { mode: GridRowModes.Edit },
    //     });
    // };

    // const handleSaveClick = (id: GridRowId) => () => {
    //     setRowModesModel({
    //         ...rowModesModel,
    //         [id]: { mode: GridRowModes.View },
    //     });
    // };

    // const handleDeleteClick = (id: GridRowId) => () => {
    //     setRows(rows?.filter((row) => row.id !== id));
    // };

    // const handleCancelClick = (id: GridRowId) => () => {
    //     setRowModesModel({
    //         ...rowModesModel,
    //         [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //     });

    //     const editedRow = rows?.find((row) => row.id === id);
    //     if (editedRow!.isNew) {
    //         setRows(rows?.filter((row) => row.id !== id));
    //     }
    // };

    return (
        <Modal
            open={open}
            onClose={() => handleIsOpen(null)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Card sx={{ padding: '10px', margin: '10px', width: '90%' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid xs={8} sx={{ alignSelf: 'center' }}>
                            <div>Student Management</div>
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                title={'Add Student'}
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: 'green',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                                onClick={handleAddStudentModal}
                            >
                                <PersonAddAlt1Icon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid xs={8} sx={{ alignSelf: 'center' }}>
                        {Datagrid({
                            sx: { height: 'unset' },
                            // rowModesModel: rowModesModel,
                            editMode: 'row',
                            columns: [
                                {
                                    field: 'id',
                                    headerName: 'ID',
                                    type: 'string',
                                    flex: 0.25,
                                },
                                {
                                    field: 'firstName',
                                    headerName: 'First Name',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'lastName',
                                    headerName: 'Last Name',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'userEmail',
                                    headerName: 'Email',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'action',
                                    headerName: 'Action',
                                    flex: 1,
                                    sortable: false,
                                    // getActions: ({ id }: GridRowParams<any>) => {
                                    //     const isInEditMode =
                                    //         rowModesModel[id]?.mode ===
                                    //         GridRowModes.Edit;

                                    //     if (isInEditMode) {
                                    //         return [
                                    //             <GridActionsCellItem
                                    //                 key={'save-' + id}
                                    //                 icon={<SaveIcon />}
                                    //                 label="Save"
                                    //                 sx={{
                                    //                     color: 'primary.main',
                                    //                 }}
                                    //                 onClick={handleSaveClick(id)}
                                    //             />,
                                    //             <GridActionsCellItem
                                    //                 key={'cancel-' + id}
                                    //                 icon={<CancelIcon />}
                                    //                 label="Cancel"
                                    //                 className="textPrimary"
                                    //                 onClick={handleCancelClick(id)}
                                    //                 color="inherit"
                                    //             />,
                                    //         ];
                                    //     }

                                    //     return [
                                    //         <GridActionsCellItem
                                    //             key={'settings-' + id}
                                    //             icon={<SettingsApplicationsIcon />}
                                    //             label="Edit"
                                    //             className="textPrimary"
                                    //             onClick={handleEditClick(id)}
                                    //             color="inherit"
                                    //         />,
                                    //         <GridActionsCellItem
                                    //             key={'delete-' + id}
                                    //             icon={<DeleteIcon />}
                                    //             label="Delete"
                                    //             onClick={handleDeleteClick(id)}
                                    //             color="inherit"
                                    //         />,
                                    //     ];
                                    // },
                                    renderCell: (params) => {
                                        return (
                                            <>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: '5px',
                                                        backgroundColor:
                                                            '#f1c40f',
                                                        border: 'solid #fff 1px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    onClick={() =>
                                                        toggleEditStudentModal(
                                                            params?.row,
                                                        )
                                                    }
                                                >
                                                    <SettingsApplicationsIcon />
                                                </IconButton>
                                                <IconButton
                                                    sx={{
                                                        marginLeft: '5px',
                                                        backgroundColor:
                                                            '#e74c3c',
                                                        border: 'solid #fff 1px',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    onClick={() => {
                                                        toggleActiveUser(
                                                            false,
                                                            params?.row
                                                                ?.userEmail,
                                                            authToken,
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        );
                                    },
                                },
                            ],
                            data: students || [],
                        })}
                    </Grid>
                </Card>
            </ThemeProvider>
        </Modal>
    );
};
