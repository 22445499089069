import React from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Divider,
    Grid,
    Typography,
    styled,
} from '@mui/material';
import { Blog } from '../interfaces/Interfaces';
import Dialog from '@mui/material/Dialog';
import { defaultTheme, formatDateNoTimeMonth } from '../utilities/Utilites';
import { DateTime } from 'luxon';

export const BlogModal = (
    blog: Blog,
    isAdmin: boolean,
    isOpen: boolean,
    setIsOpen: (open: boolean) => void,
) => {
    const styles = {
        grid: {
            marginTop: '20px',
            minHeight: '391px',
            padding: '10px',
        },
        card: {
            overflow: 'auto',
            minWidth: 0,
            marginLeft: '15px',
            marginRight: '15px',
            margin: 'auto',
            transition: '0.3s',
            minHeight: '391px',
            borderRadius: '.625rem!important',
            boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
            '&:hover': {
                boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
            },
        },
        content: {
            textAlign: 'left',
            padding: defaultTheme.spacing(3),
        },
        divider: {
            margin: `${defaultTheme.spacing(2)}px 0`,
        },
        heading: {
            fontFamily: 'sans-serif',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#3d5170',
            '&::hover': {
                color: '#2b394f',
            },
        },
        subheading: {
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '14px',
            lineHeight: 1.8,
        },
    };

    const StyledChip = styled(Chip)(() => ({
        chip: {
            color: '#FFFFFF',
            top: '0.9375rem',
            right: '0.9375rem',
            position: 'absolute',
            textTransform: 'uppercase',
        },
    }));
    const colors = (role: string) => {
        switch (role) {
            case 'PACKAGING':
                return '#dedaf4';
            case 'EDU-WELDING':
                return '#ffd6a5';
            case 'EDU-ROCKWELL':
                return '#e4f1ee';
            case 'EDU-MATERIAL HANDLING':
                return '#d9edf8';
            case 'ADMIN':
                return '#ffadad';
            default:
                return '#ffadad';
        }
    };

    const date = blog.Created_Date;
    return (
        <Dialog onClose={() => setIsOpen(false)} open={isOpen} maxWidth={'xl'}>
            <Card
                style={styles.card}
                key={'blog-card' + Math.random().toString(36).slice(2, 7)}
            >
                <CardMedia
                    style={{
                        minHeight: '20rem',
                        position: 'relative',
                        textAlign: 'right',
                    }}
                    image={blog?.Img_Url}
                >
                    {blog?.Role?.split(',').map((role) => {
                        if (role?.length && isAdmin) {
                            return (
                                <StyledChip
                                    key={
                                        role +
                                        Math.random().toString(36).slice(2, 7)
                                    }
                                    label={role}
                                    size="small"
                                    style={{
                                        backgroundColor: colors(role),
                                    }}
                                />
                            );
                        } else {
                            return <></>;
                        }
                    })}
                </CardMedia>
                <CardContent
                    style={{
                        paddingTop: '2.1875rem',
                        minHeight: '20rem',
                        paddingBottom: '0',
                        textAlign: 'left',
                        padding: defaultTheme.spacing(3),
                    }}
                >
                    <Typography
                        style={styles.heading}
                        variant={'h5'}
                        gutterBottom
                    >
                        {/* <Link
                            href={props?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                            TODO: LINK TO MODAL OR FULL BLOG
                        > */}
                        {blog?.Title}
                        {/* </Link> */}
                    </Typography>
                    <Typography style={styles.subheading} variant="body1">
                        {blog?.Content}
                    </Typography>
                </CardContent>
                <Divider style={styles.divider} />
                <CardActions
                    style={{
                        paddingLeft: '1.5625rem',
                        paddingRight: '1.5625rem',
                    }}
                >
                    <Grid
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        sx={{ fontSize: '12px' }}
                    >
                        {isAdmin ? (
                            <Grid>
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        color: '#868e96',
                                    }}
                                    gutterBottom
                                >
                                    {blog?.Author}
                                </Typography>
                            </Grid>
                        ) : (
                            <></>
                        )}
                        <Grid>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    color: '#868e96',
                                }}
                                gutterBottom
                            >
                                {formatDateNoTimeMonth(DateTime.fromISO(date))}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Dialog>
    );
};
