import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Card, Grid, Typography } from '@mui/material';
import ITALogo01 from '../styles/assets/ITALogo01.png';
import Slider from './BlogSlider';

export const LandingPage = () => {
    return (
        <ThemeProvider theme={defaultTheme}>
            {/* Uncomment and drop email html in to view and edit live in *local development only* DO NOT leave this uncommented
                <div
                dangerouslySetInnerHTML={{
                    __html: testHtml(
                        'Some Title Here',
                        DateTime.now(),
                    ),
                }}
            /> */}
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                    margin: '10px 10px 10px 10px',
                    maxWidth: '100vp',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card
                    sx={{
                        maxWidth: '100%',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <Grid container sx={{ margin: 0 }} spacing={2}>
                        <Grid
                            item
                            sx={{
                                padding: '16px',
                                margin: 'auto',
                            }}
                            xs={8}
                            md={4}
                        >
                            <img src={ITALogo01} width={'100%'} />
                        </Grid>
                        <Grid
                            item
                            sx={{ padding: '16px', textAlign: 'left' }}
                            md={8}
                            xs={12}
                        >
                            <h3>
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontFamily: 'sans-serif',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#3d5170',
                                    }}
                                >
                                    {
                                        'Welcome to the Industry Training Academy!'
                                    }
                                </Typography>
                                {/* <Typography
                                    style={{
                                        fontFamily: 'sans-serif',
                                        fontSize: '20px',
                                        lineHeight: 1.25,
                                    }}
                                >
                                    {
                                        'Insert Fancy Landing Page Text Here ... and here ... and here ... and here ... and here ... and here ... and here'
                                    }
                                </Typography> */}
                            </h3>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ margin: 0 }} spacing={2}>
                        <Grid
                            item
                            sx={{
                                padding: '16px',
                                margin: 'auto',
                            }}
                        >
                            <Slider key={'slider-root'} />
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </ThemeProvider>
    );
};
