import * as React from 'react';
import { Module, ModulePage } from '../interfaces/Interfaces';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ModulePageEditor } from './ModulePageEditor';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import ImageUploader from './ImageUploader';
import { BlobServiceClient } from '@azure/storage-blob';
import { formatDateWithTime } from '../utilities/Utilites';
import { DateTime } from 'luxon';

interface Props {
    handleToggleModulePage: (moduleId: number) => void;
    handleToggleCreateModulePage: (
        edit?: boolean,
        newPageType?: string,
    ) => void;
    handleToggleCreateAssignmentPage: (
        edit?: boolean,
        newPageType?: string,
    ) => void;
    module: Module;
    pages: Array<ModulePage>;
    getModules: () => Promise<Array<Module>>;
    getModulePages: (moduleId: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    toggleEditPage: (page: ModulePage) => void;
    toggleDeletePageModal: (id: number, refresh?: boolean) => void;
    toggleDeleteModuleModal: (id: number) => void;
}

interface ModuleBody {
    id: number;
    title: string;
    description: string;
    image: string;
    code: string;
    summary: string;
    equipment: string;
    duration: string;
    concepts: string;
    outcomes: string;
}

const ModuleDetails = ({
    handleToggleModulePage,
    handleToggleCreateModulePage,
    handleToggleCreateAssignmentPage,
    module,
    pages,
    setPages,
    getModules,
    getModulePages,
    toggleEditPage,
    toggleDeletePageModal,
}: Props) => {
    const globalState = useGlobalStore((state) => state);

    const [editting, setEditting] = React.useState(false);
    const [title, setTitle] = React.useState(module?.title || '');
    const [description, setDescription] = React.useState(
        module?.description || '',
    );
    const [code, setCode] = React.useState(module?.code || '');
    const [summary, setSummary] = React.useState(module?.summary || '');
    const [equipment, setEquipment] = React.useState(module?.equipment || '');
    const [duration, setDuration] = React.useState(module?.duration || '');
    const [concepts, setConcepts] = React.useState(module?.concepts || '');
    const [outcomes, setOutcomes] = React.useState(module?.outcomes || '');
    const [file, setFile] = React.useState<Blob | null>(null);

    let localImageUrl = module?.image;
    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };
    const changeTitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTitle(e?.currentTarget?.value);
    };

    const changeDescription = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setDescription(e?.currentTarget?.value);
    };

    const changeCode = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setCode(e?.currentTarget?.value);
    };

    const changeSummary = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setSummary(e?.currentTarget?.value);
    };

    const changeEquipment = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setEquipment(e?.currentTarget?.value);
    };

    const changeDuration = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setDuration(e?.currentTarget?.value);
    };

    const changeConcepts = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setConcepts(e?.currentTarget?.value);
    };

    const changeOutcomes = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setOutcomes(e?.currentTarget?.value);
    };

    const toggleEditting = (refresh?: boolean) => {
        const current = editting;
        setEditting(!current);
        if (refresh) {
            getModules();
        }
    };

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob | null): Promise<string> => {
        if (fileForSubmit) {
            const fileToSubmit = blobToFile(fileForSubmit);
            const blobName = `${new Date().getTime()}-${
                fileToSubmit?.name || 'update.png'
            }`; // Specify a default blob name if needed
            if (!fileToSubmit) {
                // check if the file is selected
                // alert('Please select an image to upload');
                return new Promise((res) => res(''));
            }
            if (!account || !sasToken || !containerName) {
                // check if the credentials are set
                alert(
                    'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
                );
                return new Promise((res) => res(''));
            }
            try {
                const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
                return blobClient
                    .uploadData(fileToSubmit, {
                        blobHTTPHeaders: {
                            blobContentType: fileToSubmit?.type,
                        },
                    })
                    .then(() => {
                        return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                    });
            } catch (error) {
                console.error(error); // Handle error
            }
            return new Promise((resolve) =>
                resolve(
                    `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
                ),
            );
        } else {
            return new Promise((res) => res(''));
        }
    };

    const toggleModuleActive = () => {
        const currentState = module.active || false;
        return AuthPost(
            '/organization/updatemoduleactive',
            { id: module.id, active: !currentState },
            {
                'x-access-token': globalState.authToken,
                canCache: false,
            },
        )
            .then((resp) => {
                if (resp?.status === 200) {
                    getModules();
                    return resp?.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const updateModule = async (updateBody?: ModuleBody) => {
        let data: Array<any> = [];
        handleSubmitImage(file).then(async (res) => {
            await AuthPost(
                '/organization/updatemodule',
                updateBody
                    ? updateBody
                    : {
                          id: module?.id,
                          title: title,
                          description: description,
                          image: res,
                          code: code,
                          summary: summary,
                          equipment: equipment,
                          duration: duration,
                          concepts: concepts,
                          outcomes: outcomes,
                      },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((resp) => {
                    if (resp?.status === 200) {
                        data = resp?.data;
                        return resp?.data;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
            toggleEditting(true);
            return data;
        });
    };

    return (
        <>
            <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                Published:{' '}
                {module?.publishDate
                    ? formatDateWithTime(DateTime.fromISO(module.publishDate))
                    : 'Unpublished'}
            </div>
            <Grid
                container
                style={{
                    padding: '10px 10px 10px 10px',
                    // backgroundColor: '#f44336',
                    // color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '20px',
                }}
            >
                <Grid
                    xs={4}
                    sx={{
                        textAlignLast: 'left',
                    }}
                >
                    <IconButton onClick={() => handleToggleModulePage(0)}>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                </Grid>
                <Grid xs={4} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                    {!editting ? (
                        module?.title
                    ) : (
                        <TextField
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            defaultValue={module?.title}
                            onChange={changeTitle}
                        />
                    )}
                </Grid>
                {!editting ? (
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'right',
                        }}
                    >
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: 'green',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'New Content'}
                            onClick={() =>
                                handleToggleCreateModulePage(false, 'text')
                            }
                        >
                            <NoteAddIcon />
                        </IconButton>
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: 'green',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'New Questions'}
                            onClick={() =>
                                handleToggleCreateAssignmentPage(
                                    false,
                                    'assignment',
                                )
                            }
                        >
                            <AssignmentIcon />
                        </IconButton>
                        <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: '#f1c40f',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'Edit Module'}
                            onClick={() => toggleEditting()}
                        >
                            <SettingsApplicationsIcon />
                        </IconButton>
                        {/* <IconButton
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: '#e74c3c',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            title={'Delete Module'}
                            onClick={() => {
                                toggleDeleteModuleModal(module?.id);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton> */}
                        <Button
                            variant="contained"
                            sx={{
                                // mt: 3,
                                // mb: 2,
                                marginLeft: '5px',
                                backgroundColor: module.active
                                    ? '#f44336'
                                    : 'green',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'black',
                                },
                            }}
                            onClick={toggleModuleActive}
                        >
                            {module?.active ? 'Unpublish' : 'Publish'}
                        </Button>
                    </Grid>
                ) : (
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'right',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#f44336',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'black',
                                },
                            }}
                            onClick={() => updateModule()}
                        >
                            Save
                        </Button>
                    </Grid>
                )}
            </Grid>
            <br />
            {!editting ? (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Description: {module?.description}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Code: {module?.code}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Summary: {module?.summary}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Equipment: {module?.equipment}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Duration: {module?.duration}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Concepts: {module?.concepts}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        Outcomes: {module?.outcomes}
                    </div>
                    {module?.image?.length ? (
                        <div style={{ textAlign: 'center' }}>
                            <img
                                style={{ width: '50%' }}
                                src={module?.image || ''}
                                alt="Module Details Missing Image"
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    <TextField
                        required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        defaultValue={module?.description}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeDescription}
                    />
                    <TextField
                        required
                        fullWidth
                        id="code"
                        label="Code"
                        name="code"
                        defaultValue={module?.code}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeCode}
                    />
                    <TextField
                        required
                        fullWidth
                        id="summary"
                        label="Summary"
                        name="summary"
                        defaultValue={module?.summary}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeSummary}
                    />
                    <TextField
                        required
                        fullWidth
                        id="equipment"
                        label="Equipment"
                        name="equipment"
                        defaultValue={module?.equipment}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeEquipment}
                    />
                    <TextField
                        required
                        fullWidth
                        id="concepts"
                        label="Concepts"
                        name="concepts"
                        defaultValue={module?.concepts}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeConcepts}
                    />
                    <TextField
                        required
                        fullWidth
                        id="outcomes"
                        label="Outcomes"
                        name="outcomes"
                        defaultValue={module?.outcomes}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeOutcomes}
                    />
                    <TextField
                        required
                        fullWidth
                        id="duration"
                        label="Duration"
                        name="duration"
                        defaultValue={module?.duration}
                        sx={{ marginBottom: '16px' }}
                        onChange={changeDuration}
                    />
                    <ImageUploader
                        setFile={(newFile) => setFile(newFile)}
                        imageUrl={localImageUrl}
                        file={file}
                        changeUrl={changeImage}
                        deleteCallback={() =>
                            updateModule({
                                id: module?.id,
                                title: title,
                                description: description,
                                image: '',
                                code: code,
                                summary: summary,
                                equipment: equipment,
                                duration: duration,
                                concepts: concepts,
                                outcomes: outcomes,
                            })
                        }
                    />
                </>
            )}

            <br />
            <ModulePageEditor
                handleToggleCreateModulePage={handleToggleCreateModulePage}
                module={module}
                pages={pages}
                getModulePages={getModulePages}
                setPages={setPages}
                toggleEditPage={toggleEditPage}
                toggleDeletePageModal={toggleDeletePageModal}
            />
        </>
    );
};

export { ModuleDetails };
