import React from 'react';
// import { Module, ModulePage } from '../interfaces/Interfaces';
import {
    Button,
    Card,
    CardHeader,
    Grid,
    IconButton,
    TextField,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import { ModulePageEditor } from './ModulePageEditor';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { BlobServiceClient } from '@azure/storage-blob';
import ImageUploader from '../modules/ImageUploader';
import { Datagrid } from '../utilities/Datagrid';
import { AxiosResponse } from 'axios';
import {
    ApiOrganization,
    Class,
    Module,
    ModulePage,
    Question,
} from '../interfaces/Interfaces';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AssignmentsTab } from '../courses/AssignmentsTab';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { CreateEditStudentModal } from './CreateEditStudentModal';
import { GradeBookDatagrid } from './GradeBookDatagrid';

interface Props {
    isStudent: boolean | undefined;
    handleToggleModulePage: (moduleId: number) => void;
    handleToggleAddModule: () => void;
    toggleGradeBookModal: (studentID?: number, moduleID?: number) => void;
    classData: Class;
    modules: Array<Module>;
    setCurrentPage: (page: number) => void;
    setViewModuleDetails: (module: number) => void;
    getModulePages: (id: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    pages: Array<ModulePage>;
    questions: Array<Question>;
    disableForward: boolean;
    setDisableForward: (disable: boolean) => void;
    selectedClassID: number | undefined;
    toggleBack?: () => void;
    refreshModules?: () => void;
    showStudentGradebook?: (studentID?: number, moduleID?: number) => void;
    orgList: Array<ApiOrganization>;
    getClasses: () => void;
}

const ClassDetails = ({
    isStudent,
    handleToggleModulePage,
    handleToggleAddModule,
    toggleGradeBookModal,
    classData,
    modules,
    setCurrentPage,
    getModulePages,
    setPages,
    pages,
    questions,
    disableForward,
    setDisableForward,
    selectedClassID,
    toggleBack,
    showStudentGradebook,
    refreshModules,
    orgList,
    getClasses,
    setViewModuleDetails,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    // const [viewModuleDetails, setViewModuleDetails] = React.useState<number>(0);
    const [editting, setEditting] = React.useState(false);
    const [editStudentModalIsOpen, setEditStudentModalIsOpen] =
        React.useState(false);
    const [title, setTitle] = React.useState(classData?.className || '');
    const [students, setStudents] = React.useState<any>([]);
    const [description, setDescription] = React.useState(
        classData?.classDescription || '',
    );
    const [file, setFile] = React.useState<Blob | null>(null);
    const [modulesLocal, setModulesLocal] = React.useState<Array<Module>>();
    const [selectedModule, setSelectedModule] = React.useState<
        number | undefined
    >();
    React.useEffect(() => {
        setModulesLocal(modules);
    }, [modules]);

    const getOrganization = () => {
        let classStudents: any = [];
        if (globalState?.user?.orgId) {
            AuthPost(
                '/organization/organization',
                { id: globalState?.user?.orgId },
                {
                    'x-access-token': globalState?.authToken || '',
                    canCache: true,
                },
            )
                .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                    // setOrganization(res?.data[0]);
                    console.log('res?.data[0]?.users', res?.data[0]?.users);
                    classStudents = Array.from(
                        new Set(
                            res?.data[0]?.users?.filter((user: any) => {
                                return user.type.includes('Student');
                            }),
                        ),
                    );
                    return classStudents;
                })
                .then((classStus) => {
                    AuthPost(
                        '/userclass/getclassusers',
                        { classID: classData.classID },
                        {
                            'x-access-token': globalState?.authToken || '',
                            canCache: true,
                        },
                    ).then(
                        async (res: AxiosResponse<Array<ApiOrganization>>) => {
                            console.log('get class users', res?.data);
                            const ids = await res?.data.map(
                                (obj: any) => obj.userID,
                            );
                            console.log('ids', ids);
                            console.log('classStus', classStus);
                            const newStudentsList = await classStus.filter(
                                (student: any) => {
                                    console.log('student.id', student.id);
                                    console.log(
                                        'filter',
                                        ids.includes(student.id),
                                    );
                                    return ids.includes(student.id);
                                },
                            );
                            console.log('newStudentsList', newStudentsList);
                            if (newStudentsList?.length) {
                                setStudents(newStudentsList);
                            }
                        },
                    );
                });
        }
    };

    React.useEffect(() => {
        getOrganization();
    }, []);
    const toggleSelectedModule = (id: number) => {
        setSelectedModule(id !== selectedModule ? id : undefined);
    };

    let localImageUrl = classData?.image;
    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };
    const changeTitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTitle(e?.currentTarget?.value);
    };

    const changeDescription = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setDescription(e?.currentTarget?.value);
    };

    const toggleEditting = () => {
        // const toggleEditting = (refresh?: boolean) => {
        const current = editting;
        setEditting(!current);
        // if (refresh) {
        //     getModules();
        // }
    };

    const removeModuleFromClass = (moduleID: number, classID: number) => {
        AuthPost(
            '/classdetail/removeclassdetail',
            {
                classID: classID,
                moduleID: moduleID,
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Module Removed Successfully!');
                }
                if (refreshModules) {
                    refreshModules();
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob | null): Promise<string> => {
        if (fileForSubmit) {
            const fileToSubmit = blobToFile(fileForSubmit);
            const blobName = `${new Date().getTime()}-${
                fileToSubmit?.name || 'update.png'
            }`; // Specify a default blob name if needed
            if (!fileToSubmit) {
                // check if the file is selected
                // alert('Please select an image to upload');
                return new Promise((res) => res(''));
            }
            if (!account || !sasToken || !containerName) {
                // check if the credentials are set
                alert(
                    'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
                );
                return new Promise((res) => res(''));
            }
            try {
                const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
                return blobClient
                    .uploadData(fileToSubmit, {
                        blobHTTPHeaders: {
                            blobContentType: fileToSubmit?.type,
                        },
                    })
                    .then(() => {
                        return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                    });
            } catch (error) {
                console.error(error); // Handle error
            }
            return new Promise((resolve) =>
                resolve(
                    `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
                ),
            );
        } else {
            return new Promise((res) => res(''));
        }
    };

    const updateClass = async (updateBody?: Class) => {
        let data: Array<any> = [];
        handleSubmitImage(file).then(async (res) => {
            await AuthPost(
                '/class/updateclass',
                updateBody
                    ? updateBody
                    : {
                          classID: classData?.classID,
                          className: title,
                          classDescription: description,
                          image: res,
                          class: classData?.class,
                          endDate: classData?.endDate,
                          startDate: classData?.startDate,
                      },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((resp) => {
                    if (resp?.status === 200) {
                        data = resp?.data;
                        return resp?.data;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
            // toggleEditting(true);
            toggleEditting();
            return data;
        });
    };

    const toggleEditStudentModal = () => {
        const current = editStudentModalIsOpen;
        setEditStudentModalIsOpen(!current);
    };

    const addStudentToClass = (studentId: string) => {
        return AuthPost(
            '/userclass/adduserclass',
            { userID: studentId, classID: classData?.class },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getClasses();
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    const classOrg = orgList?.filter(
        (org) => org.id?.toString() === classData?.orgID?.toString(),
    )[0];
    const classInstructor = classOrg?.users?.filter(
        (user) => user.id?.toString() === classData?.instructorID?.toString(),
    )[0];

    return isStudent ? (
        <>
            <AssignmentsTab
                questions={questions}
                modules={modules}
                pages={pages}
                currentPage={globalState?.currentPage || 0}
                setCurrentPage={setCurrentPage}
                getModulePages={getModulePages}
                setPages={setPages}
                disableForward={disableForward}
                setDisableForward={setDisableForward}
                classID={selectedClassID}
                toggleBack={toggleBack}
                showGradebook={showStudentGradebook}
                setViewModuleDetailsParent={setViewModuleDetails}
            />
        </>
    ) : (
        <ThemeProvider theme={defaultTheme}>
            {CreateEditStudentModal({
                open: editStudentModalIsOpen,
                handleClose: () => setEditStudentModalIsOpen(false),
                organization: classOrg,
                authToken: globalState.authToken,
                selectedInstructor: classInstructor,
                addStudentToClass: addStudentToClass,
                selectedStudent: null,
            })}
            <Card
                style={{
                    width: '100%',
                }}
            >
                <Grid
                    container
                    style={{
                        padding: '10px 10px 10px 10px',
                        // backgroundColor: '#f44336',
                        // color: '#fff',
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}
                >
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'left',
                        }}
                    >
                        <IconButton onClick={() => handleToggleModulePage(0)}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{ alignSelf: 'center', textAlign: 'center' }}
                    >
                        {!editting ? (
                            classData?.className
                        ) : (
                            <TextField
                                required
                                fullWidth
                                id="title"
                                label="Title"
                                name="title"
                                defaultValue={classData?.className}
                                onChange={changeTitle}
                            />
                        )}
                    </Grid>
                    {!editting ? (
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: '#f1c40f',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                                title={'Edit Class'}
                                onClick={() => toggleEditting()}
                            >
                                <SettingsApplicationsIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: '#e74c3c',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                                title={'Delete Class'}
                                // onClick={() => {
                                //     toggleDeleteModuleModal(module?.id);
                                // }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#f44336',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        color: 'black',
                                    },
                                }}
                                onClick={() => updateClass()}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <br />
                {!editting ? (
                    <>
                        <div style={{ textAlign: 'center' }}>
                            Invite Code: {classData?.class}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            {classData?.classDescription}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            Year: {classData?.year}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            Term: {classData?.term}
                        </div>
                        {classData?.image?.length ? (
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    style={{ width: '50%' }}
                                    src={classData?.image || ''}
                                    alt="Module Details Missing Image"
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <>
                        <TextField
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            defaultValue={classData?.classDescription}
                            sx={{ marginBottom: '16px' }}
                            onChange={changeDescription}
                        />
                        <ImageUploader
                            setFile={(newFile) => setFile(newFile)}
                            imageUrl={localImageUrl}
                            file={file}
                            changeUrl={changeImage}
                            deleteCallback={() =>
                                updateClass({
                                    ...classData,
                                    className: title,
                                    classDescription: description,
                                    image: '',
                                })
                            }
                        />
                    </>
                )}
            </Card>
            <br />
            {/* <ModulePageEditor
                handleToggleCreateModulePage={handleToggleCreateModulePage}
                module={module}
                pages={pages}
                getModulePages={getModulePages}
                setPages={setPages}
                toggleEditPage={toggleEditPage}
                toggleDeletePageModal={toggleDeletePageModal}
            /> */}
            <Card
                style={{
                    width: '100%',
                }}
            >
                <Grid container sx={{ padding: '16px' }}>
                    <Grid
                        xs={4}
                        sx={{
                            margin: '0',
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontWeight: '400',
                            fontSize: '1.5rem',
                            lineHeight: '1.334',
                            letterSpacing: '0em',
                            display: 'block',
                            textAlignLast: 'left',
                            aligntContent: 'center',
                        }}
                    >
                        Roster
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{ alignSelf: 'center', textAlign: 'center' }}
                    ></Grid>
                    <Grid
                        xs={4}
                        sx={{
                            textAlignLast: 'right',
                        }}
                    >
                        <IconButton
                            title={'Add Student'}
                            sx={{
                                marginLeft: '5px',
                                backgroundColor: 'green',
                                border: 'solid #fff 1px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '20px',
                            }}
                            onClick={toggleEditStudentModal}
                        >
                            <PersonAddAlt1Icon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid xs={8} sx={{ alignSelf: 'center' }}>
                    {Datagrid({
                        sx: { height: 'unset' },
                        // rowModesModel: rowModesModel,
                        editMode: 'row',
                        columns: [
                            {
                                field: 'id',
                                headerName: 'ID',
                                type: 'string',
                                flex: 0.25,
                            },
                            {
                                field: 'firstName',
                                headerName: 'First Name',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'lastName',
                                headerName: 'Last Name',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'userEmail',
                                headerName: 'Email',
                                type: 'string',
                                flex: 1,
                            },
                        ],
                        data: students,
                    })}
                </Grid>
            </Card>
            <br />
            <GradeBookDatagrid
                classID={selectedClassID}
                classStudents={students}
                modules={modules}
                toggleGradebookModal={toggleGradeBookModal}
            />
            <br />
            <Card
                style={{
                    width: '100%',
                }}
            >
                <CardHeader title="Content" />
                <Grid container>
                    {
                        <Card
                            onClick={handleToggleAddModule}
                            sx={{
                                width: '200px',
                                height: '300px',
                                margin: '10px',
                                textAlign: 'center',
                                boxShadow:
                                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                            }}
                        >
                            <AddCircleIcon
                                key={'blog-grid-slider-add-icon'}
                                sx={{
                                    paddingTop: '65px',
                                    width: '150px',
                                    height: '150px',
                                    color: `${defaultTheme.palette.primary.main}`,
                                }}
                            />
                        </Card>
                    }
                    {modulesLocal?.map((module) => {
                        return (
                            <div
                                key={module.id}
                                onClick={() => toggleSelectedModule(module.id)}
                                className={
                                    selectedModule === module.id
                                        ? 'Border-Selected-500'
                                        : ''
                                }
                                style={{ position: 'relative' }}
                            >
                                {selectedModule === module.id ? (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            right: '10px',
                                            marginLeft: '5px',
                                            border: 'solid #fff 1px',
                                            color: '#e74c3c',
                                            fontWeight: 'bold',
                                            fontSize: '20px',
                                        }}
                                        title="Remove"
                                        onClick={() => {
                                            removeModuleFromClass(
                                                module.id,
                                                classData.classID,
                                            );
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                                <ModulePreviewCard
                                    key={module.id}
                                    handleToggleModulePage={() => {
                                        globalState.setCurrentModule(
                                            module?.id,
                                        );
                                        return getModulePages(module?.id).then(
                                            (res) => {
                                                setPages(
                                                    res?.sort(
                                                        (a: any, b: any) =>
                                                            Number(
                                                                a.pageNumber,
                                                            ) -
                                                            Number(
                                                                b.pageNumber,
                                                            ),
                                                    ),
                                                );
                                            },
                                        );
                                    }}
                                    module={module}
                                />
                            </div>
                        );
                    })}
                </Grid>
            </Card>
        </ThemeProvider>
    );
};

export { ClassDetails };
