import * as React from 'react';
import { Card, Grid, IconButton } from '@mui/material';
// import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState, Module } from '../interfaces/Interfaces';
import { useGlobalStore } from '../utilities/GlobalState';
import { AuthPost } from '../utilities/Routing/Requests';
import { Datagrid } from '../utilities/Datagrid';
// import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

export interface Props {
    modules: Array<Module>;
    classID?: number;
    classStudents?: Array<any>;
    toggleGradebookModal: (id: number, moduleID?: number) => void;
    // userType: string;
}

export const GradeBookDatagrid = ({
    modules,
    classID,
    classStudents,
    toggleGradebookModal,
}: // userType,
Props) => {
    const globalState = useGlobalStore((state: GlobalState) => state);
    const [gradebookData, setGradebookData] = React.useState<Array<any>>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const buildGradebookObj = async (
        graded: number,
        moduleID: number,
        assessmentDetailID: string,
        answer: string,
        uniqueID: string,
        correct?: boolean,
    ) => {
        return AuthPost(
            '/assessmentdetail/getAssessmentDetailByID',
            { assessmentDetailID: assessmentDetailID },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((data) => {
                let resolvedAnswer = answer;
                if (data.data.type === 'mc') {
                    resolvedAnswer = JSON.parse(data.data.assessmentChoices)[
                        answer
                    ];
                }
                return {
                    id: uniqueID,
                    moduleID: moduleID,
                    answer: resolvedAnswer,
                    question: data.data.assessmentDetail,
                    type: data.data.type,
                    correctAnswer: JSON.parse(data.data.correctAnswer),
                    correct: correct,
                    assessmentDetailID: assessmentDetailID,
                    assessmentChoices: JSON.parse(data.data.assessmentChoices),
                    graded: graded,
                };
            })
            .catch((err) => {
                console.error(err.toString());
            });
    };
    interface GBObj {
        name: string;
        id: number;
        modulesCompletedArray: Array<any>;
        gradebook: Array<any>;
    }
    let tempData: Array<GBObj> = [];

    const checkModuleProgress = (
        moduleID: number,
        modulePageID: number,
        userID?: number,
    ) => {
        const progress = AuthPost(
            '/progress/getuserprogress',
            {
                classID: classID,
                userID: userID || globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
            });
        return progress.then((res) => {
            if (res?.recordset?.length) {
                return res?.recordset?.every(
                    (obj: any) => obj?.status === 'complete',
                );
            } else {
                return false;
            }
        });
    };

    const generateDatagrid = () => {
        const columns: Array<any> = [
            {
                field: 'name',
                headerName: 'Student',
                type: 'string',
                flex: 1,
            },
        ];
        modules.forEach((mod, i: number) => {
            columns.push({
                field: 'gradebook' + i,
                headerName: mod.title,
                valueGetter: (params: any) => {
                    return params?.row.gradebook;
                },
                type: 'string',
                flex: 1,
                renderCell: (rowData: any) => {
                    const gradesForModule = rowData?.row?.gradebook?.filter(
                        (gbItem: any) => {
                            return gbItem?.moduleID === mod.id;
                        },
                    );
                    const allQuestionsAnswered =
                        rowData?.row?.modulesCompletedArray?.[i];

                    const questionsNotGraded = gradesForModule.some(
                        (gbItem: any) => !gbItem.graded,
                    );
                    let countCorrect = 0;
                    gradesForModule?.forEach((grade: any) => {
                        if (grade?.correct) {
                            countCorrect++;
                        }
                    });
                    if (!allQuestionsAnswered) {
                        return <>Incomplete</>;
                    } else if (allQuestionsAnswered && questionsNotGraded) {
                        return (
                            <>
                                <IconButton
                                    sx={{
                                        marginLeft: '5px',
                                        backgroundColor: '#f1c40f',
                                        border: 'solid #fff 1px',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                    title="Grade Assignments"
                                    onClick={() =>
                                        toggleGradebookModal(
                                            rowData?.row?.id,
                                            mod.id,
                                        )
                                    }
                                >
                                    <PendingActionsIcon />
                                </IconButton>
                            </>
                        );
                    } else {
                        return (
                            <a
                                onClick={() => {
                                    toggleGradebookModal(
                                        rowData?.row?.id,
                                        mod.id,
                                    );
                                }}
                                className="Nav-Link"
                            >
                                {countCorrect}/{gradesForModule?.length}
                            </a>
                        );
                    }
                },
            });
        });
        return Datagrid({
            sx: { height: 'unset' },
            // rowModesModel: rowModesModel,
            editMode: 'row',
            columns: columns,
            data: gradebookData,
        });
    };

    const fetchGradebookData = async (
        studentID: number,
        studentName: string,
    ) => {
        // console.log('fetch user gradebook id', studentID);
        const modulesCompletedArray = modules.map((mod) =>
            checkModuleProgress(mod.id, 0, studentID),
        );
        const getBody = {
            classID: classID,
            userID: studentID,
        };
        await Promise.all(modulesCompletedArray).then((modProms) => {
            AuthPost('/gradebook/getusergradebook', getBody, {
                'x-access-token': globalState.authToken,
                canCache: false,
            })
                .then(async (res) => {
                    if (res?.status === 200 || res?.status === 204) {
                        // console.log('get user gradebook response', res.data);
                        const gbData = res.data?.map(
                            (gradebookItem: any, i: number) => {
                                // console.log('gradebookItem', gradebookItem);
                                const uniqueID =
                                    gradebookItem.assessmentDetailID +
                                    gradebookItem.userID.toString() +
                                    i.toString();
                                return buildGradebookObj(
                                    gradebookItem.graded,
                                    gradebookItem.moduleID,
                                    gradebookItem.assessmentDetailID,
                                    gradebookItem.answer,
                                    uniqueID,
                                    gradebookItem.correct,
                                );
                            },
                        );
                        await Promise.all(gbData).then((resProms) => {
                            tempData = [
                                ...tempData,
                                {
                                    name: studentName || '',
                                    id: studentID,
                                    modulesCompletedArray: modProms,
                                    gradebook: resProms,
                                },
                            ];
                        });
                    } else {
                        return [];
                    }
                })
                .then(() => {
                    setGradebookData(tempData);
                })
                .catch((err) => {
                    console.error(err.toString());
                });
        });
    };

    React.useEffect(() => {
        // console.log('classStudents', classStudents);
        setLoading(true);
        classStudents?.map((stu: any) => {
            // console.log('student', stu);
            return fetchGradebookData(
                stu.id,
                stu.firstName + ' ' + stu.lastName,
            );
        });
        setLoading(false);
    }, [classStudents]);

    return (
        <Card
            style={{
                width: '100%',
            }}
        >
            <Grid container sx={{ padding: '16px' }}>
                <Grid
                    xs={8}
                    sx={{
                        margin: '0',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        fontWeight: '400',
                        fontSize: '1.5rem',
                        lineHeight: '1.334',
                        letterSpacing: '0em',
                        display: 'block',
                        textAlignLast: 'left',
                        aligntContent: 'center',
                    }}
                >
                    Gradebook
                </Grid>
            </Grid>
            <Grid xs={8} sx={{ alignSelf: 'center' }}>
                {/* <>{console.log('gradebookData', gradebookData)}</> */}
                {!loading && generateDatagrid()}
            </Grid>
        </Card>
        // <Card
        //     sx={{
        //         width: '800px',
        //         margin: '10px',
        //         outline: 'none',
        //         overflow: 'auto',
        //     }}
        // >
        //     <Box
        //         sx={{
        //             margin: '20px',
        //             display: 'flex',
        //             flexDirection: 'column',
        //             alignItems: 'center',
        //         }}
        //     >
        //         <Grid container>
        //             {modules.map((mod) => {
        //                 return (
        //                     <>
        //                         <Grid
        //                             sx={{
        //                                 textAlignLast: 'center',
        //                             }}
        //                             xs={12}
        //                         >
        //                             <Card
        //                                 key={mod.id}
        //                                 sx={{
        //                                     margin: '0 10px 0 10px',
        //                                     outline: 'none',
        //                                     width: '100%',
        //                                 }}
        //                             >
        //                                 <Typography component="h1" variant="h5">
        //                                     {`Grade Book - Course: ${mod.title}`}
        //                                 </Typography>
        //                             </Card>
        //                         </Grid>
        //                         <Grid
        //                             sx={{
        //                                 textAlignLast: 'center',
        //                             }}
        //                             xs={12}
        //                         >
        //                             {gradebook?.length > 0 &&
        //                                 gradebook.map((gradebookItem: any) => {
        //                                     return (
        //                                         <div key={gradebookItem?.id}>
        //                                             <GradebookAnswerCard
        //                                                 question={
        //                                                     gradebookItem?.question ||
        //                                                     ''
        //                                                 }
        //                                                 answer={
        //                                                     gradebookItem?.answer ||
        //                                                     ''
        //                                                 }
        //                                                 type={
        //                                                     gradebookItem?.type ||
        //                                                     ''
        //                                                 }
        //                                                 correct={
        //                                                     gradebookItem?.correct
        //                                                 }
        //                                                 authToken={
        //                                                     globalState.authToken
        //                                                 }
        //                                                 userID={studentID}
        //                                                 assessmentDetailID={
        //                                                     gradebookItem?.assessmentDetailID
        //                                                 }
        //                                                 classID={classID}
        //                                                 userType={userType}
        //                                                 correctAnswersMC={
        //                                                     gradebookItem?.correctAnswer ||
        //                                                     []
        //                                                 }
        //                                                 assessmentChoices={
        //                                                     gradebookItem?.assessmentChoices ||
        //                                                     []
        //                                                 }
        //                                             />
        //                                         </div>
        //                                     );
        //                                 })}
        //                         </Grid>
        //                     </>
        //                 );
        //             })}
        //         </Grid>
        //     </Box>
        // </Card>
    );
};
