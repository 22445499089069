import React, { useEffect, useState } from 'react';
import { BlogCard } from './BlogCard';
import { Grid, IconButton } from '@mui/material';
import { getAllBlogs } from '../utilities/Utilites';
import { useGlobalStore } from '../utilities/GlobalState';
import { Blog } from '../interfaces/Interfaces';
import { DateTime } from 'luxon';
import { BlogModal } from './BlogModal';
import { CreateBlogModal } from './CreateBlogModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AxiosResponse } from 'axios';
import { EditBlogModal } from './EditBlogModal';
import { DeleteBlogModal } from './DeleteBlogModal';

// wrapper for items
const Slider = () => {
    const [itemRows, setItemRows] = useState<Array<Blog>>([]);
    const [blog, setSelectedBlog] = useState<Blog>();
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModelIsOpen] = useState(false);
    const [createModalIsOpen, setCreateModelIsOpen] = useState(false);
    const [editModalIsOpen, setEditModelIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModelIsOpen] = useState(false);

    const globalState = useGlobalStore((state) => state);
    const userName = globalState.authUsername;
    const isAdmin = globalState.isAdmin;
    useEffect(() => {
        getAllBlogs(globalState?.authToken)
            .then((blogs: AxiosResponse<Array<Blog>>) => {
                return blogs;
            })
            .then((response) => {
                const sortedBlogs = response?.data?.sort((a, b) => {
                    return (
                        DateTime.fromISO(b.Created_Date).toMillis() -
                        DateTime.fromISO(a.Created_Date).toMillis()
                    );
                });
                setItemRows(sortedBlogs);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    }, [isAdmin]);

    const refreshBlogs = () => {
        getAllBlogs(globalState?.authToken)
            .then((blogs: AxiosResponse<Array<Blog>>) => {
                return blogs;
            })
            .then((response) => {
                const sortedBlogs = response?.data?.sort((a, b) => {
                    return (
                        DateTime.fromISO(b.Created_Date).toMillis() -
                        DateTime.fromISO(a.Created_Date).toMillis()
                    );
                });
                setItemRows(sortedBlogs);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    return (
        <Grid container spacing={1} key={'slider-root-grid-container'}>
            {!loading && isAdmin ? (
                <div
                    style={{
                        minWidth: 0,
                        marginLeft: '15px',
                        marginRight: '15px',
                        margin: 'auto',
                        transition: '0.3s',
                        borderRadius: '.625rem!important',
                        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
                    }}
                    key={'blog-grid-slider-add-button-container'}
                >
                    <IconButton
                        key={'blog-grid-slider-add-iconbutton'}
                        onClick={() => setCreateModelIsOpen(!createModalIsOpen)}
                    >
                        <AddCircleIcon
                            key={'blog-grid-slider-add-icon'}
                            sx={{
                                width: '150px',
                                height: '150px',
                                color: '#f44336',
                            }}
                        />
                    </IconButton>
                </div>
            ) : (
                <div key={'slider-root-empty'}></div>
            )}
            {!loading &&
                itemRows?.length > 0 &&
                itemRows.map((row: Blog) => {
                    return BlogCard(
                        row,
                        isAdmin,
                        setSelectedBlog,
                        setModelIsOpen,
                        modalIsOpen,
                        setEditModelIsOpen,
                        setDeleteModelIsOpen,
                        row.ID,
                    );
                })}
            {!loading &&
                itemRows?.length > 0 &&
                blog &&
                BlogModal(blog, isAdmin, modalIsOpen, setModelIsOpen)}
            {!loading &&
                isAdmin &&
                CreateBlogModal(
                    refreshBlogs,
                    createModalIsOpen,
                    setCreateModelIsOpen,
                    userName,
                    globalState.authToken,
                )}
            {!loading &&
                isAdmin &&
                blog &&
                EditBlogModal(
                    refreshBlogs,
                    editModalIsOpen,
                    setEditModelIsOpen,
                    userName,
                    globalState.authToken,
                    blog,
                )}
            {!loading &&
                isAdmin &&
                blog &&
                DeleteBlogModal(
                    refreshBlogs,
                    deleteModalIsOpen,
                    setDeleteModelIsOpen,
                    globalState.authToken,
                    blog,
                )}
        </Grid>
    );
};
export default Slider;
