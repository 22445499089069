import { AxiosResponse } from 'axios';
import { AuthGet, AuthPost } from './Routing/Requests';
import { Link, Typography, createTheme } from '@mui/material';
import * as React from 'react';
import { red } from '@mui/material/colors';
import { DateTime } from 'luxon';
import {
    ApiCustomer,
    ApiUser,
    Blog,
    Customer,
    MultiSelectOption,
    OrganizationUser,
} from '../interfaces/Interfaces';
import UAParser from 'ua-parser-js';

const toggleActiveUser = (
    active: boolean,
    email: string,
    authToken: string,
) => {
    const userBody = {
        email: email,
        active: active,
    };

    AuthPost('/user/active', userBody, {
        'x-access-token': authToken,
    })
        .then(() => {
            return;
        })
        .catch((err) => {
            console.error(err.toString());
        });
};

const editUser = (
    userEmail?: string,
    firstName?: string,
    lastName?: string,
    orgId?: string,
    token?: string,
    type?: string,
    students?: string,
    instructor?: string,
    classes?: string,
    answers?: string,
) => {
    // Check if credentials are valid before using
    if (userEmail && firstName && lastName && orgId && token) {
        AuthPost(
            '/user/edit',
            {
                userEmail,
                firstName,
                lastName,
                orgId,
                type,
                students,
                instructor,
                classes,
                answers,
            },
            {
                'x-access-token': token,
                canCache: true,
            },
        ).then((res: AxiosResponse<OrganizationUser>) => {
            if (res?.status === 200 || res?.status === 201) {
                return res;
            } else {
                throw new Error(res?.toString() || 'error');
            }
        });
    } else {
        throw new Error('Invalid or Missing Data');
    }
};

const editUserActive = (email: string, active: boolean, token: string) => {
    // Check if credentials are valid before using
    if (email && active !== null && active !== undefined && token) {
        return AuthPost(
            '/user/active',
            { email, active },
            {
                'x-access-token': token,
                canCache: true,
            },
        ).then((res: AxiosResponse<Array<ApiUser>>) => {
            if (res?.status === 200) {
                return res;
            } else {
                throw new Error(res.toString());
            }
        });
    } else {
        throw new Error('Invalid or Missing Data');
    }
};

const genericBlogFetch = (route: string, token: string) => {
    return AuthGet(route, {
        'x-access-token': token,
        canCache: true,
    }).then((res: AxiosResponse) => {
        if (res?.status === 200) {
            return res;
        } else {
            console.error(res);
            return res;
        }
    });
};

const getAllBlogs = (token: string): Promise<AxiosResponse<Array<Blog>>> =>
    genericBlogFetch('/blog/all', token);

const genericCustomerFetch = (
    route: string,
    customerId: string,
    token: string,
) => {
    return AuthGet(route, {
        Customer_id: customerId,
        'x-access-token': token,
        canCache: true,
    }).then((res: AxiosResponse) => {
        if (res?.status === 200) {
            return res;
        } else {
            throw new Error(res.toString());
        }
    });
};

const fetchCustomer = (
    customerId: string,
    token: string,
): Promise<AxiosResponse<Customer>> =>
    genericCustomerFetch('/Customers/customer_id', customerId, token);

// TODO remove ANY
// eslint-disable-next-line
const Copyright = (props: any) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            <Link color="inherit" href="https://aptmfg.com/">
                Industry Training Academy
            </Link>
            {' © '}
            {DateTime.now().setZone('America/New_York').toFormat('yyyy')}
        </Typography>
    );
};

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#00aeef',
        },
        secondary: {
            main: red[500],
        },
        warning: {
            main: '#FFEA00',
        },
    },
});

const formatDateNoTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const formatDateNoTimeMonth = (date: DateTime) => {
    return date.toFormat('MM-dd-yyyy'); // MM-dd-yyyy
};

const formatTimeNoDate = (date: DateTime) => {
    return date.toFormat('HH:mm:ss'); // HH:mm:ss
};

const formatTimeNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm a ZZZZ'); // h:mm PM EDT (12 hour format)
};

const formatTimeWithSecondsNoDateIncludeTZ = (date: DateTime) => {
    return date.toFormat('h:mm:ss a ZZZZ'); // h:mm:ss PM EDT (12 hour format)
};

const formatDateWithTime = (date: DateTime) => {
    return date.toFormat('yyyy-MM-dd HH:mm:ss'); // yyyy-MM-dd HH:mm:ss
};

const formatDateWithTime2 = (date: DateTime) => {
    return date.toFormat('MM-dd-yyyy HH:mm:ss'); // MM-dd-yyyy HH:mm:ss
};

const formatDateWithTimeAndT = (date: DateTime) => {
    return date.toFormat("yyyy-MM-dd'T'HH:mm:ss"); // yyyy-MM-dd'T'HH:mm:ss
};

const fetchEmployee = (username: string) => {
    return AuthGet('/GetEmployeeUsername', {
        employeeUsername: username,
        canCache: true,
    });
};

const convertMinutesToHours = (mins: number) => {
    const hours = mins / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rhours + ' hrs ' + rminutes + ' mins';
};

const getDateThisWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekStart = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const getDateLastWeekEnd = () => {
    return DateTime.now()
        .setZone('America/New_York')
        .minus({ days: 7 })
        .startOf('week')
        .plus({ days: 6 })
        .toFormat('yyyy-MM-dd'); // yyyy-MM-dd
};

const isAdmin = (roles: string) => roles.indexOf('ADMIN') > -1;

const customerSelectOptionMapper = (customerArr: Array<ApiCustomer>) =>
    customerArr?.map((cx) => {
        return {
            label: cx.name,
            id: cx.customer,
        };
    });

const customerIdStringToSelectOption = (
    customerArr: Array<ApiCustomer>,
    customer: string,
) => {
    const customerFiltered = customerArr?.filter(
        (cx) => cx.customer === customer,
    )[0];
    return {
        label: customerFiltered.name,
        id: customerFiltered.customer,
    };
};

const compareArrays = (
    array1: Array<MultiSelectOption>,
    array2: Array<MultiSelectOption>,
) => {
    const array1sorted = array1.sort((a, b) => Number(a.key) - Number(b.key));
    const array2sorted = array2.sort((a, b) => Number(a.key) - Number(b.key));

    return array1sorted.every((value, index) => {
        return value?.key === array2sorted[index]?.key;
    });
};

const getUserAgentDetails = () => {
    const parser = new UAParser();
    return {
        browser: parser.getBrowser(),
        engine: parser.getEngine(),
        os: parser.getOS(),
        device: parser.getDevice(),
        cpu: parser.getCPU(),
    };
};

export {
    defaultTheme,
    Copyright,
    formatDateNoTime,
    formatDateWithTime,
    fetchEmployee,
    formatTimeNoDate,
    getDateLastWeekStart,
    getDateLastWeekEnd,
    getDateThisWeekStart,
    convertMinutesToHours,
    formatTimeNoDateIncludeTZ,
    formatDateNoTimeMonth,
    formatDateWithTimeAndT,
    formatTimeWithSecondsNoDateIncludeTZ,
    fetchCustomer,
    editUser,
    editUserActive,
    customerSelectOptionMapper,
    compareArrays,
    genericCustomerFetch,
    customerIdStringToSelectOption,
    isAdmin,
    getAllBlogs,
    toggleActiveUser,
    getUserAgentDetails,
    formatDateWithTime2,
};
