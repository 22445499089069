import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState } from '../interfaces/Interfaces';
import { BlobServiceClient } from '@azure/storage-blob';
import ImageUploader from './ImageUploader';
import { v1 as uuidv1 } from 'uuid';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
}

export const CreateModuleModal = ({ open, handleClose }: Props) => {
    const [file, setFile] = React.useState<Blob | null>(null);
    const globalState = useGlobalStore((state: GlobalState) => state);

    let localImageUrl = null;

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob): Promise<string> => {
        const fileToSubmit = blobToFile(fileForSubmit);
        const blobName = `${new Date().getTime()}-${
            fileToSubmit?.name || 'create.png'
        }`; // Specify a default blob name if needed
        if (!fileToSubmit) {
            // check if the file is selected
            // alert('Please select an image to upload');
            return new Promise((res) => res(''));
        }
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
            );
            return new Promise((res) => res(''));
        }
        try {
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            return blobClient
                .uploadData(fileToSubmit, {
                    blobHTTPHeaders: { blobContentType: fileToSubmit?.type },
                })
                .then(() => {
                    return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                });
        } catch (error) {
            console.error(error); // Handle error
        }
        return new Promise((res) =>
            res(
                `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
            ),
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (file) {
            handleSubmitImage(file).then((imageRes) => {
                const body = {
                    title: data.get('title'),
                    description: data.get('description'),
                    code: data.get('code'),
                    summary: data.get('summary'),
                    equipment: data.get('equipment'),
                    duration: data.get('duration'),
                    concepts: data.get('concepts'),
                    outcomes: data.get('outcomes'),
                    image: imageRes,
                    moduleGUID: uuidv1(),
                };
                AuthPost('/organization/createmodule', body, {
                    'x-access-token': globalState.authToken,
                })
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.success('Module Successfully Created!');
                            handleClose(true);
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString());
                    });
            });
        } else {
            const body = {
                title: data.get('title'),
                description: data.get('description'),
                moduleGUID: uuidv1(),
                code: data.get('code'),
                summary: data.get('summary'),
                equipment: data.get('equipment'),
                duration: data.get('duration'),
                concepts: data.get('concepts'),
                outcomes: data.get('outcomes'),
            };
            AuthPost('/organization/createmodule', body, {
                'x-access-token': globalState.authToken,
            })
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success('Module Successfully Created!');
                        handleClose(true);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Module
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1, alignItems: 'center' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Code"
                            name="code"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="summary"
                            label="Summary"
                            name="summary"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="equipment"
                            label="Equipment"
                            name="equipment"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="duration"
                            label="Duration"
                            name="duration"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="concepts"
                            label="Concepts"
                            name="concepts"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="outcomes"
                            label="Outcomes"
                            name="outcomes"
                        />
                        <ImageUploader
                            setFile={(newFile) => setFile(newFile)}
                            imageUrl={localImageUrl}
                            file={file}
                            changeUrl={changeImage}
                            deleteCallback={() => {}}
                        />
                        {/* <Button
                            name="image"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={addImage}
                        >
                            COVER IMAGE
                        </Button> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE MODULE
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
